
import { Vue, Options } from 'vue-class-component';
import { LegalPolicy } from '@/lib/components';
import { DEFAULT_LEGAL_REGION } from '@/constants/legal';

@Options({
  components: { LegalPolicy },
  props: {
    branding: {
      type: Array,
      default: () => []
    },
    customers: {
      type: Array,
      default: () => []
    },
    countryCode: {
      type: String,
      default: DEFAULT_LEGAL_REGION
    }
  }
})
export default class PrivacyPolicyPage extends Vue {
}
